import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import SenseiParams from './SenseiParams'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import axios from 'axios'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    fileUpload
} from '../../lib/skycity.js'

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        'serviceMode': [
            { label: 'Cutout', value: 'cutout', selected: true },
            { label: 'Mask', value: 'mask', selected: false },
        ],
        'maskFormat': [
            { label: 'JPEG', value: 'jpeg', selected: true },
            { label: 'PNG', value: 'png', selected: false },
            { label: 'webP', value: 'webp', selected: false }
        ],
        "outputQuality": 95,
        'trim': false
    })

    function formatParams(params) {
        let mode = params.serviceMode.find((mode) => mode.selected).value
        let mask_format = params.maskFormat.find((format) => format.selected).value
        let trim = params.trim
        let quality = Number.parseInt(params.outputQuality)
        return {
            "mode": mode,
            "mask_format": `image/${mask_format}`,
            "trim": trim,
            "output_quality": quality
        }
    }

    async function start (curFile) {
        const formattedParams = formatParams(params)
        const outputFileExtension = (formattedParams.mask_format === "image/jpeg") ? "jpg" : (formattedParams.mask_format == "image/png") ? "png" : "webp";
        const outputFileName = `imagecutoutOnSensei_${uuid()}_${curFile.shortName}.${outputFileExtension}`
        const filePath = `${curFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'ImageCutout V4 Sync on CPF',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Feature:autocrop:Service-e4c2aec8002943a797840574eab514eb'
                        },
                        'sensei:params': formattedParams,
                        'sensei:inputs': {
                            image_in: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': filePath
                            }
                        },
                        'sensei:outputs': {
                            masks_out: {
                                "dc:format": `${formattedParams.mask_format}`,
                                "sensei:multipart_field_name": "masks_out"
                            },
                            metadata_out: {
                                "dc:format": "application/json",
                                "sensei:multipart_field_name": "metadata_out"
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        try {
            let responseBody = await ssApiSyncCall(config);
            const contentAnalyzerResponse = responseBody.get("contentAnalyzerResponse")
            let response = JSON.parse(responseBody.get("metadata_out"))
            response["sensei_metadata"] = JSON.parse(contentAnalyzerResponse)
            const output = responseBody.get("masks_out0")
            let blob = output.slice(0, output.size, 'image/png'); 
            let newFile = new File([blob], outputFileName, {type: formattedParams.mask_format});

            // TODO: currently CPF Sync service doesn't support file uploader. So we can only return blob back.
            // However our output Image Asset has to be a src url.. So we need to upload blob to CC storage.
            // Upload file object to CC storage
            let file_response = await fileUpload(newFile, outputFileName)
            let outputHref = `${getTempDirectory()}/${outputFileName}`

            // Skycity library to be udpated for Sensei response
            if (files.find(file => file.inputFile === curFile)) {
                return
            }

            files.push({
                inputFile: curFile,
                name: getFilename(outputHref),
                shortName: getFilenameWithoutExt(outputHref),
                path: outputHref,
                outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
            })

            dispatch({
                type: 'skycity-success',
                payload: {
                    files: files,
                    response: response,
                    loading: false,
                    wait: false
                }
            })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(start(curFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
