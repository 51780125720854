import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import SenseiParams from './SenseiParams'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    fileUpload
} from '../../lib/skycity.js'

import axios from 'axios'

const uuid = require('uuid/v4')

export default function Operation () {
    const {
        errorOptions,
        dispatch,
        inputFiles,
        inputSecondFiles
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        x: "0",
        y: "0"
    })

    function formatParams (rawParams) {
        return {
            x: Number.parseInt(rawParams.x),
            y: Number.parseInt(rawParams.y)
        }
    }

    async function uploadFile(responseBody, partName) {
        const output = responseBody.get(partName)
        const blob = output.slice(0, output.size, 'image/png')

        const outputFileName = `${partName}_${uuid()}.png`
        const outputHref = `${getTempDirectory()}/${outputFileName}`
        const newFile = new File([blob], outputFileName, { type: 'image/png' })

        await fileUpload(newFile, outputFileName)

        return outputHref
    }


    async function start (curFile, secondFile) {
        const files = []
        const outputFileName = `parametricharmonization_${uuid()}_${curFile.shortName}.png`
        const foregroundFilePath = `${curFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        const backgroundFilePath = `${secondFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`

        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'ParametricHarmonization',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-5fa9480fdd9a4e48a0c3198cbeba9e77'
                        },
                        'sensei:params': formatParams(params),
                        'sensei:inputs': {
                            foreground: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': foregroundFilePath
                            },
                            background: {
                                'dc:format': checkFileType(secondFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': backgroundFilePath
                            }
                        },
                        'sensei:outputs': {
                            composite: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'composite'
                            },
                            rgb_curve: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'rgb_curve'
                            },
                            shading_map: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'shading_map'
                            },
                            metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata'
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        try {
            const responseBody = await ssApiSyncCall(config)
            const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
            const metadata = JSON.parse(responseBody.get('metadata'))

            responseBody.sensei_metadata = JSON.parse(contentAnalyzerResponse)
            const hrefs = []
            hrefs.push(await uploadFile(responseBody, 'composite'))
            hrefs.push(await uploadFile(responseBody, 'rgb_curve'))
            hrefs.push(await uploadFile(responseBody, 'shading_map'))

            for (let outputHref of hrefs) {
                files.push({
                    inputFile: curFile,
                    name: getFilename(outputHref),
                    shortName: getFilenameWithoutExt(outputHref),
                    path: outputHref,
                    outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                    output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                    outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                })
            }

            dispatch({
                type: 'skycity-success',
                payload: {
                    files: files,
                    response: metadata,
                    loading: false,
                    wait: false
                }
            })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            const funcArray = []
            inputFiles.forEach(inputFile => {
                inputSecondFiles.forEach(inputSecondFile => {
                    funcArray.push(start(inputFile, inputSecondFile))
                })
            })

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
