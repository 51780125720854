import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    fileUpload
} from '../../lib/skycity.js'

import axios from 'axios'

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile, curMaskFile) {
        console.log('START CALLED')
        const outputFileName = `trimapmatting_${uuid()}_${curFile.shortName}.png`
        const imagePath = `${curFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        const maskPath = `${curMaskFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_ML_APIKEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'Image Cutout V4 on CPF',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Feature:autocrop:Service-e4c2aec8002943a797840574eab514eb'
                        },
                        'sensei:params': {
                            'mode': 'hair_refinement'
                        },
                        'sensei:inputs': {
                            image_in: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': imagePath
                            },
                            mask_in: {
                                'dc:format': checkFileType(curMaskFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': maskPath
                            }
                        },
                        'sensei:outputs': {
                            mask: {
                                "dc:format": `image/png`,
                                "sensei:multipart_field_name": "mask"
                            },
                            metadata: {
                                "dc:format": "application/json",
                                "sensei:multipart_field_name": "metadata"
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        try {
            let responseBody = await ssApiSyncCall(config);
            const contentAnalyzerResponse = responseBody.get("contentAnalyzerResponse")
            let response = JSON.parse(responseBody.get("metadata"))
            response["sensei_metadata"] = JSON.parse(contentAnalyzerResponse)
            const output = responseBody.get("mask")
            let blob = output.slice(0, output.size, 'image/png'); 
            let newFile = new File([blob], outputFileName, {type: `image/png`});

            // TODO: currently CPF Sync service doesn't support file uploader. So we can only return blob back.
            // However our output Image Asset has to be a src url.. So we need to upload blob to CC storage.
            // Upload file object to CC storage
            let file_response = await fileUpload(newFile, outputFileName)
            let outputHref = `${getTempDirectory()}/${outputFileName}`

            // Skycity library to be udpated for Sensei response
            if (files.find(file => file.inputFile === curFile)) {
                return
            }

            files.push({
                inputFile: curFile,
                name: getFilename(outputHref),
                shortName: getFilenameWithoutExt(outputHref),
                path: outputHref,
                outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
            })

            dispatch({
                type: 'skycity-success',
                payload: {
                    files: files,
                    response: response,
                    loading: false,
                    wait: false
                }
            })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                inputSecondFiles.forEach(inputSecondFile => {
                    funcArray.push(start(inputFile, inputSecondFile))
                })
            })
            console.log('number of functions: ' + funcArray.length)

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function checkFileType (type) {
        if (type.toLowerCase() === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
