import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import axios from 'axios'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    fileUpload
} from '../../lib/skycity.js'

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile) {
        const filePath = `${curFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'Entity Segmentation',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Feature:autocrop:Service-bc6778f21a914e0b97823f6b4cab0a47'
                        },
                        'sensei:inputs': {
                            image: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': filePath
                            }
                        },
                        'sensei:outputs': {
                            semantic_masks: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'semantic_masks'
                            },
                            stuff_masks: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'stuff_masks'
                            },
                            metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata'
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const responseBody = await ssApiSyncCall(config)
        const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
        const response = JSON.parse(responseBody.get('metadata'))
        const sensei_metadata = JSON.parse(contentAnalyzerResponse)
        const masks = sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].semantic_masks
        const stuff_masks = sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].stuff_masks
        const metadata_out = sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].metadata

        const entities = JSON.parse(responseBody.get('metadata'))

        const uploads = []
        // upload semantic_masks
        for (let i = 0; i < masks.length; ++i) {
            const output = responseBody.get(masks[i]['sensei:multipart_field_name'])
            const blob = output.slice(0, output.size, 'image/png')
            const outputFileName = `entityseg_mask_${uuid()}_${curFile.shortName}.png`
            const newFile = new File([blob], outputFileName, { type: 'image/png' })
            const outputHref = `${getTempDirectory()}/${outputFileName}`

            files.push({
                inputFile: curFile,
                name: getFilename(outputHref),
                shortName: getFilenameWithoutExt(outputHref),
                path: outputHref,
                outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
            })

            uploads.push(fileUpload(newFile, outputFileName))
        }
        // upload stuff masks
        for (let i = 0; i < stuff_masks.length; ++i) {
            const output = responseBody.get(stuff_masks[i]['sensei:multipart_field_name'])
            const blob = output.slice(0, output.size, 'image/png')
            const outputFileName = `entityseg_stuff_mask_${uuid()}_${curFile.shortName}.png`
            const newFile = new File([blob], outputFileName, { type: 'image/png' })
            const outputHref = `${getTempDirectory()}/${outputFileName}`

            files.push({
                inputFile: curFile,
                name: getFilename(outputHref),
                shortName: getFilenameWithoutExt(outputHref),
                path: outputHref,
                outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
            })

            uploads.push(fileUpload(newFile, outputFileName))
        }
        await Promise.all(uploads)

        dispatch({
            type: 'skycity-success',
            payload: {
                files: files,
                response: entities,
                loading: false,
                wait: false
            }
        })
    }

    async function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(start(curFile))
            })
            await axios.all(funcArray)
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
