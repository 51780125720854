import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import ACRMaskOptions from '../Shared/ACRMaskOptions'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import { getFilename, getFilenameWithoutExt, getHeaders } from '../../lib/skycity.js'

import axios from 'axios'

// eslint-disable-next-line import/no-webpack-loader-syntax
import TiffToJPEGWorker from "worker-loader!../Shared/ACRTiffToJPEGWorker";

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch,
        lrACRMaskOptions
    } = useContext(SkycityContext)

    async function fileUpload (fileToUpload, url) {
        const uuidNum = uuid()
        dispatch({ type: 'set-input-local', payload: { wait: true, files: inputFiles, file: { progress: 0, name: fileToUpload.name } } })
        const config = {
            headers: {
                'Content-Type': 'application/octet-stream',
                'Access-Control-Allow-Origin': '*'
            },
            onUploadProgress: (e) => {
                const currentProgress = (parseInt(e.loaded) / parseInt(e.total) * 100).toFixed(0)
                const curFile = {}
                curFile.name = fileToUpload.name
                curFile.progress = currentProgress
                dispatch({ type: 'set-input-local', payload: { wait: true, files: inputFiles, file: { progress: currentProgress, name: fileToUpload.name } } })
            },
            timeout: 300000
        }
        return axios.put(url, fileToUpload, config)
    }

    async function fileDownload (url) {
        const config = {
            headers: {
                'Content-Type': 'application/octet-stream',
                'Access-Control-Allow-Origin': '*'
            },
            timeout: 300000,
            responseType: 'arraybuffer'
        }
        return axios.get(url, config)
    }

    function downloadMask (mask) {
        const tiffToJPEGWorker = new TiffToJPEGWorker();
        return new Promise(async (resolve, reject) => {
            const rawImage = await fileDownload(mask);
            tiffToJPEGWorker.onmessage = (base64StringOutput) => {
                resolve(base64StringOutput.data);
                tiffToJPEGWorker.terminate();
            }
            tiffToJPEGWorker.postMessage(rawImage.data);
        });
    }

    function start (curFile) {
        /* *********************************** */
        const codePayload = {
            options: lrACRMaskOptions
        }
        const config = {
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_ACR}/mask`,
            method: 'POST',
            timeout: 90000,
            headers: getHeaders(),
            payload: codePayload,
            body: JSON.stringify(codePayload),
            localFilePath: curFile.path
        }
        /* *********************************** */

        apiCall(config, async (links, json) => {
            let id;
            if (links && links.upload && links.upload.href) {
                const uploadHref = links.upload.href
                await fileUpload(curFile.file, uploadHref)
                id = links.self.href
            } else { // If no upload URL then it is the referenceJobId workflow
                id = links
            }
            if (id) {
                getStatus(id, config, async (res, error, errResponse) => {
                    codePayload.options.referenceJobId = res.jobId
                    dispatch({ type: 'set-codes', payload: { config: config } })
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.status === 'failed') {
                        const errMsg = res.errors ? `${id} - ${JSON.stringify(res.errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${errMsg}`, errResponse || res)
                    } else if (res && res.status === 'succeeded') {
                        let outputs = [];
                        let downloadMasks = [];
                        for(let i = 0; i < res.outputs._links.length; i++) {
                            const mask = res.outputs._links[i].href;
                            if(mask) {
                                outputs = outputs.concat({
                                    inputFile: {
                                        base64: curFile.base64
                                    },
                                    name: getFilename(mask),
                                    shortName: getFilenameWithoutExt(mask),
                                    path: mask,
                                    outputToDownload: mask
                                });
                                downloadMasks = downloadMasks.concat(downloadMask(mask));
                            }
                        }
                        const masks = await Promise.all(downloadMasks);
                        for(let i = 0; i < masks.length; i++) {
                            outputs[i] = {
                                ...outputs[i],
                                ...{
                                    output: masks[i],
                                    outputThumbnail: masks[i]
                                }
                            };
                        }
                        dispatch({
                            type: 'skycity-success',
                            payload: {
                                files: outputs,
                                selectedInputFile: outputs[0],
                                showLayerInfoPane: true,
                                loading: false,
                                wait: false,
                                response: res
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0 && lrACRMaskOptions.maskType) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <ACRMaskOptions />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
