import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiCall, getSenseiStatus } from '../../lib/ccApi.js'
import {
    getAssetUrn,
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders
} from '../../lib/skycity.js'

import axios from 'axios'

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    async function start (curFile) {
        const tempURN = await getAssetUrn(`${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getTempDirectory()}`)
        const assetURN = await getAssetUrn(curFile.url)
        const outputFileName = `bodyparser_${uuid()}_${curFile.shortName}`
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'BodyParser',
                'sensei:invocation_mode': 'asynchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:body-crop:Service-fe6cf97a08a04f2e87b2151bc4f9a698'
                        },
                        'sensei:inputs': {
                            image_in: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'RAPI',
                                'repo:id': assetURN
                            }
                        },
                        'sensei:outputs': {
                            image_out: {
                                'repo:id': tempURN,
                                'dc:format': 'image/png',
                                'sensei:repoType': 'RAPI',
                                'repo:resource': {
                                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                                    path: `${outputFileName}.png`
                                }
                            },
                            json_out: {
                                'repo:id': tempURN,
                                'dc:format': 'application/json',
                                'sensei:repoType': 'RAPI',
                                'repo:resource': {
                                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                                    path: `${outputFileName}.json`
                                }
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const bodyParserResponse = await ssApiCall(config)
        if (bodyParserResponse && bodyParserResponse.status < 400) {
            const senseiStatusResponse = await getSenseiStatus(bodyParserResponse.getResponseHeader('location'))
            if (senseiStatusResponse && senseiStatusResponse.status === '200') {
                const outputHref = `${getTempDirectory()}/${outputFileName}.png`
                const outputJsonHref = `${getTempDirectory()}/${outputFileName}.json`
                const outputJsonURL = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputJsonHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                const outputJsonResponse = await axios.get(outputJsonURL)

                if (outputJsonResponse && outputJsonResponse.status === 200) {
                    // Skycity library to be udpated for Sensei response
                    if (files.find(file => file.inputFile === curFile)) {
                        return
                    }
                    const response = { ...senseiStatusResponse, ...outputJsonResponse.data }

                    files.push({
                        inputFile: curFile,
                        name: getFilename(outputHref),
                        shortName: getFilenameWithoutExt(outputHref),
                        path: outputHref,
                        outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/:rendition;size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                        output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/:rendition;size=0?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                        outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                    })
                    dispatch({
                        type: 'sensei-success',
                        payload: {
                            files: files,
                            response: response
                        }
                    })
                }
            } else {
                const errMsg = senseiStatusResponse.errors ? `${JSON.stringify(senseiStatusResponse.errors, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, senseiStatusResponse)
            }
        } else {
            const errMsg = bodyParserResponse ? `${JSON.stringify(bodyParserResponse.response, null, 2)}` : `ERROR with ${config.url}`
            errorOccured(`${errMsg}`, bodyParserResponse)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
