import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    fileUpload
} from '../../lib/skycity.js'

import SenseiParams from './SenseiParams'

import axios from 'axios'

const uuid = require('uuid/v4')

export default function Operation () {
    const {
        errorOptions,
        dispatch,
        inputFiles,
        inputSecondFiles,
        inputThirdFiles
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        version: [
            { label: 'v1', value: 'v1', selected: true },
            { label: 'v2', value: 'v2', selected: false }
        ]
    })

    async function start (image, background, objectMasks) {
        const files = []

        const imgFilePath = `${image.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        const backgroundFilePath = `${background.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        const masks = []
        for (let i = 0; i < objectMasks.length; i++) {
            const maskPath = `${objectMasks[i].url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
            masks.push({
                'dc:format': checkFileType(objectMasks[i].type),
                'sensei:repoType': 'HTTP',
                'repo:path': maskPath
            })
        }

        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'Layer Scene Completion',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-6f8d9639dfa74b1897498cd96a9c6527'
                        },
                        'sensei:params': {
                            version: params.version.find(v => v.selected).value
                        },
                        'sensei:inputs': {
                            image: {
                                'dc:format': checkFileType(image.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': imgFilePath
                            },
                            background: {
                                'dc:format': checkFileType(background.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': backgroundFilePath
                            },
                            object_masks: masks
                        },
                        'sensei:outputs': {
                            completed_objects: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'completed_objects'
                            },
                            detected_objects: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'detected_objects'
                            },
                            metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata'
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: image,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const responseBody = await ssApiSyncCall(config)
        const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
        const response = JSON.parse(responseBody.get('metadata'))
        response.sensei_metadata = JSON.parse(contentAnalyzerResponse)
        response.detected_objects = JSON.parse(responseBody.get('detected_objects'))
        const responseImages = response.sensei_metadata.statuses[0].invocations[0]['sensei:outputs'].completed_objects

        const uploads = []
        for (let i = 0; i < responseImages.length; ++i) {
            const output = responseBody.get(responseImages[i]['sensei:multipart_field_name'])
            const blob = output.slice(0, output.size, 'image/png')

            const outputFileName = `layerscene_${uuid()}_${image.shortName}.png`
            const newFile = new File([blob], outputFileName, { type: 'image/png' })
            const outputHref = `${getTempDirectory()}/${outputFileName}`

            files.push({
                inputFile: image,
                name: getFilename(outputHref),
                shortName: getFilenameWithoutExt(outputHref),
                path: outputHref,
                outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
            })

            uploads.push(fileUpload(newFile, outputFileName))
        }
        await Promise.all(uploads)

        dispatch({
            type: 'skycity-success',
            payload: {
                files: files,
                response: response,
                loading: false,
                wait: false
            }
        })
    }

    async function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            const funcArray = []
            inputFiles.forEach(inputFile => {
                inputSecondFiles.forEach(secondFile => {
                    funcArray.push(start(inputFile, secondFile, inputThirdFiles))
                })
            })

            await axios.all(funcArray)
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        } finally {
            console.log('finally...')
            dispatch({
                type: 'sensei-end-wait'
            })
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={async e => await run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
