import React, { useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'

import Checkbox from '@react/react-spectrum/Checkbox'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import Textfield from '@react/react-spectrum/Textfield'

export default function SenseiParams (props) {
    function setOption (field, selected) {
        const options = {}
        options[field] = props.params[field].map(option => {
            option.selected = false
            if (option.value === selected) {
                option.selected = true
            }
            return option
        })

        props.setParams({ ...props.params, ...options })
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <React.Fragment>
                    <Heading className="adobe-skycity-subtitle1" variant="subtitle1">sensei:params</Heading>
                    <Well>
                        <div className="adobe-skycity-options-col">
                            <div className="adobe-skycity-maskrefinement-option">
                                <FieldLabel className="adobe-skycity-options-field" label="mode" labelFor="idMode" position="left">
                                    <OverlayTrigger placement="right">
                                        <Select
                                            options={props.params.refineMode}
                                            onChange={e => setOption('refineMode', e)}
                                            id="idMode" />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_refineMode_maskrefinement')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}