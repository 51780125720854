import React, { useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import { error } from '@react/react-spectrum/Toast'

import Checkbox from '@react/react-spectrum/Checkbox'
import Textfield from '@react/react-spectrum/Textfield'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'


export default function SenseiParams (props) {
    function setOption (field, selected) {
        const options = {}
        options[field] = props.params[field].map(option => {
            option.selected = false
            if (option.value === selected) {
                option.selected = true
            }
            return option
        })

        props.setParams({ ...props.params, ...options })
    }

    function toggleTrim () {
        const trim = !props.params.trim
        props.setParams({ ...props.params, trim: trim })
    }

    function setText(name, value) {
        const variable = {}
        variable[name] = value
        props.setParams({ ...props.params, ...variable })
    }

    try {
        return <div className="adobe-skycity-output-options">
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_output_options')}</Heading>
                <React.Fragment>
                    <Heading className="adobe-skycity-subtitle1" variant="subtitle1">Sensei Params</Heading>
                    <Well>
                        <div className="adobe-skycity-options-col">
                            <div className="adobe-skycity-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Service Mode" labelFor="idServiceMode" position="left">
                                    <OverlayTrigger placement="right">
                                        <Select
                                            options={props.params.serviceMode}
                                            onChange={e => setOption('serviceMode', e)}
                                            id="idServiceMode" />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_servicemode_imagecutoutv4sensei')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Mask Format" labelFor="idMaskFormat" position="left">
                                    <OverlayTrigger placement="right">
                                        <Select
                                            options={props.params.maskFormat}
                                            onChange={e => setOption('maskFormat', e)}
                                            id="idMaskFormat" />
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_maskformat_imagecutoutv4sensei')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-option">
                                <FieldLabel className="adobe-skycity-options-field" label="Output Quality" labelFor="idOutputQuality" position="left">
                                    <OverlayTrigger placement="right">
                                        <Textfield
                                            defaultValue={95}
                                            value={props.params.output_quality}
                                            onChange={ e => setText('outputQuality', e)}
                                            label="OutputQuality"
                                            id="idOutputQuality">
                                        </Textfield>
                                        <Tooltip variant="info">
                                            {__('output_option_tooltip_outputquality_imagecutoutv4sensei')}
                                        </Tooltip>
                                    </OverlayTrigger>
                                </FieldLabel>
                            </div>
                            <div className="adobe-skycity-option">
                                <OverlayTrigger placement="right">
                                    <Checkbox value={props.params.trim}
                                        defaultChecked={props.params.trim}
                                        onChange={ e => toggleTrim()}
                                        label="Trim">
                                    </Checkbox>
                                    <Tooltip variant="info">
                                        {__('output_option_tooltip_invertmask_imagecutoutv4sensei')}
                                    </Tooltip>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Well>
                </React.Fragment>
            </Well>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
