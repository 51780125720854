import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'
import Well from '@react/react-spectrum/Well'
import Heading from '@react/react-spectrum/Heading'

import SelectInput from '../Shared/SelectInput'
import OutputOptions from '../Shared/OutputOptions'

import {
    getFilename,
    getFilenameWithoutExt,
    getStorage,
    getOptionsOutput,
    getHeaders,
    getEncodedURI
} from '../../lib/skycity.js'

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        inputSecondFile,
        outputOptions,
        dsInputSecondFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const d3 = require('d3')
    const sdk = require('@adobe/aio-lib-photoshop-api')

    async function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            let count = 1
            const csvFile = inputSecondFiles[0].url.match(/cc-api-storage-creativesdk/) ? `${inputSecondFiles[0].url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}` : inputSecondFiles[0].url
            d3.csv(csvFile, (row) => {
                console.log(row)
                const layers = []
                var keys = Object.keys(row)
                for (const key of keys) {
                    layers.push({
                        name: key,
                        input: {
                            href: row[key],
                            storage: row[key].match(/files/) ? sdk.Storage.ADOBE : sdk.Storage.EXTERNAL
                        }
                    })
                }
                start(layers, count)
                count++
            })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    async function start (layers, count) {
        files = []
        for (const curFile of inputFiles) {
            const client = await sdk.init(null, process.env.REACT_APP_CONFIG_APP_KEY, window.adobeIMS.getAccessToken().token)
            const input = {
                storage: getStorage(curFile),
                href: curFile.path
            }
            const output = getOptionsOutput(curFile, outputOptions)
            const option = { layers }
            const config = {
                url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/smartObject`,
                method: 'POST',
                timeout: 3000000000,
                headers: getHeaders(),
                payload: {
                    input: input,
                    output: output,
                    options: option
                },
                body: JSON.stringify({
                    input: input,
                    output: output,
                    options: option
                })
            }
            dispatch({ type: 'set-codes', payload: { config: config } })

            const res = await client.replaceSmartObject(input, output, option)
            if (res.isDone) {
                for (const output of res.outputs) {
                    if (output.errors) {
                        const errMsg = `ERROR: ${JSON.stringify(res)}`
                        errorOccured(errMsg)
                    } else {
                        for (const rendition of output._links.renditions) {
                            const outputHref = rendition.href
                            files.push({
                                inputFile: curFile,
                                name: getFilename(outputHref),
                                shortName: getFilenameWithoutExt(outputHref),
                                path: outputHref,
                                outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/:rendition;size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/:rendition;size=0?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                                outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                            })
                        }
                    }
                }
                dispatch({
                    type: 'skycity-success',
                    payload: {
                        files: files,
                        selectedInputFile: files[0],
                        loading: false,
                        wait: false,
                        response: res
                    }
                })
            } else {
                const errMsg = `ERROR: ${JSON.stringify(res)}`
                errorOccured(errMsg)
            }
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0 &&
            inputSecondFiles && inputSecondFiles.length > 0 &&
            outputOptions && outputOptions.find(output => output.selected === true)) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_select_csv')}</Heading>
                <br />
                <SelectInput
                    storage={['cc', 's3']}
                    actionType="set-input-second"
                    title={__('skycity_comp_title_select_csv')}
                    imageTypes=".csv,text/csv"
                    multiple={false}
                    files={inputSecondFiles}
                    file={inputSecondFile}
                    dsFiles={dsInputSecondFiles}/>
            </Well>
            <br />
            <OutputOptions imageTypes={['image/jpeg', 'image/png', 'image/vnd.adobe.photoshop', 'image/tiff']}/>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
