import React, { useContext } from 'react'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Home from '../Home/Main'
import PsArtboard from '../PsArtboard/Main'
import PsSmartObject from '../PsSmartObject/Main'
import PsSmartObjectV2 from '../PsSmartObjectV2/Main'
import PsSmartObjectCsv from '../PsSmartObjectCsv/Main'
import PsInsertLayer from '../PsInsertLayer/Main'
import PsCrop from '../PsCrop/Main'
import PsDocumentManifest from '../PsDocumentManifest/Main'
import PsEdit from '../PsEdit/Main'
import PsReflowCep from '../PsReflowCep/Main'
import PsReflowWeb from '../PsReflowWeb/Main'
import PsFileConversion from '../PsFileConversion/Main'
import PsText from '../PsText/Main'
import PsProductCrop from '../PsProductCrop/Main'
import PsSocialMediaRendition from '../PsSocialMediaRendition/Main'
import LrMasking from '../LrMasking/Main'
import LrMaskingPredict from '../LrMaskingPredict/Main'
import LrAutotone from '../LrAutotone/Main'
import LrAutostraighten from '../LrAutostraighten/Main'
import LrApplyEdit from '../LrApplyEdit/Main'
import LrApplyPreset from '../LrApplyPreset/Main'
import SsImageCutout from '../SsImageCutout/Main'
import SsImageCutoutOnSensei from '../SsImageCutoutOnSensei/Main'
import SsImageCutoutOnSenseiAsync from '../SsImageCutoutOnSenseiAsync/Main'
import SsPieOnSensei from '../SsPieOnSensei/Main'
import SsGeneration from '../SsGeneration/Main'
import SsAutoTag from '../SsAutoTag/Main'
import SsBodyParser from '../SsBodyParser/Main'
import SsDepthEstimation from '../SsDepthEstimation/Main'
import SsInteractiveSelection from '../SsInteractiveSelection/Main'
import SsObjectSelection from '../SsObjectSelection/Main'
import SsDOMO from '../SsDOMO/Main'
import SsLayerSceneCompletion from '../SsLayerSceneCompletion/Main'
import SsSwappingAutoencoder from '../SsSwappingAutoencoder/Main'
import SsSshHarmonization from '../SsSshHarmonization/Main'
import SsSemanticHarmonization from '../SsSemanticHarmonization/Main'
import SsCMGanHoleFilling from '../SsCMGanHoleFilling/Main'
import SsShadowDetection from '../SsShadowDetection/Main'
import SsShadowRemoval from '../SsShadowRemoval/Main'
import SsShadowGenerator from '../SsShadowGenerator/Main'
import SsTrimapMatting from '../SsTrimapMatting/Main'
import SsUniversalRefinement from '../SsUniversalRefinement/Main'
import SsMaskRefinement from '../SsMaskRefinement/Main'
import SsSelectAll from '../SsSelectAll/Main'
import SsMetaCAF from '../SsMetaCAF/Main'
import SsEntitySegmentation from '../SsEntitySegmentation/Main'
import SsDistractorDetection from '../SsDistractorDetection/Main'
import SsParametricHarmonization from '../SsParametricHarmonization/Main'
import SsSuperResolution from '../SsSuperResolution/Main'
import PocLoc from '../PocLoc/Main'
import PsActions from '../PsActions/Main'
import PsUXP from '../PsUXP/Main'
import ToolHealthCheck from '../ToolHealthCheck/Main'
import ToolTest from '../ToolTest/Main'
import Template from '../Template/Main'
import Onboarding from '../Onboarding/Main'

import Wait from '@react/react-spectrum/Wait'

export default function Feature ({ page }) {
    const { appProgress, appProgressMsg } = useContext(SkycityContext)

    return (
        <React.Fragment>
            { appProgress && <div className="adobe-skycity-modal-loading">
                <Wait size="L" />
                <div>{appProgressMsg}</div>
            </div> }
            {page === 'home' && <Home />}
            {page === 'psfileconversion' && <PsFileConversion />}
            {page === 'psinsertlayer' && <PsInsertLayer />}
            {page === 'pssmartobject' && <PsSmartObject />}
            {page === 'pssmartobjectV2' && <PsSmartObjectV2 />}
            {page === 'pssmartobjectCsv' && <PsSmartObjectCsv />}
            {page === 'pscrop' && <PsCrop />}
            {page === 'psdocumentmanifest' && <PsDocumentManifest />}
            {page === 'psedit' && <PsEdit />}
            {page === 'psreflowcep' && <PsReflowCep />}
            {page === 'psreflowweb' && <PsReflowWeb />}
            {page === 'psproductcrop' && <PsProductCrop />}
            {page === 'pssocialmediarendition' && <PsSocialMediaRendition />}
            {page === 'lrautotone' && <LrAutotone />}
            {page === 'lrmasking' && <LrMasking />}
            {page === 'lrmaskingpredict' && <LrMaskingPredict />}
            {page === 'lrautostraighten' && <LrAutostraighten />}
            {page === 'lrapplyedit' && <LrApplyEdit />}
            {page === 'lrapplypreset' && <LrApplyPreset />}
            {page === 'ssimagecutout' && <SsImageCutout />}
            {page === 'ssimagecutoutonsensei' && <SsImageCutoutOnSensei />}
            {page === 'ssimagecutoutonsenseiasync' && <SsImageCutoutOnSenseiAsync />}
            {page === 'ssbodyparser' && <SsBodyParser />}
            {page === 'ssdepthestimation' && <SsDepthEstimation />}
            {page === 'ssinteractiveselection' && <SsInteractiveSelection />}
            {page === 'ssobjectselection' && <SsObjectSelection />}
            {page === 'ssdomo' && <SsDOMO />}
            {page === 'sslayerscene' && <SsLayerSceneCompletion />}
            {page === 'ssswappingautoencoder' && <SsSwappingAutoencoder />}
            {page === 'sssshharmonization' && <SsSshHarmonization />}
            {page === 'sssemanticharmonization' && <SsSemanticHarmonization />}
            {page === 'sscmganholefilling' && <SsCMGanHoleFilling />}
            {page === 'ssshadowdetection' && <SsShadowDetection />}
            {page === 'ssshadowremoval' && <SsShadowRemoval />}
            {page === 'ssshadowgenerator' && <SsShadowGenerator />}
            {page === 'sstrimapmatting' && <SsTrimapMatting />}
            {page === 'ssuniversalrefinement' && <SsUniversalRefinement />}
            {page === 'ssmaskrefinement' && <SsMaskRefinement />}
            {page === 'ssselectall' && <SsSelectAll />}
            {page === 'ssmetacaf' && <SsMetaCAF />}
            {page === 'ssentitysegmentation' && <SsEntitySegmentation />}
            {page === 'ssdistractordetection' && <SsDistractorDetection />}
            {page === 'ssparametricharmonization' && <SsParametricHarmonization />}
            {page === 'sssuperresolution' && <SsSuperResolution />}
            {page === 'sspieonsensei' && <SsPieOnSensei />}
            {page === 'ssgeneration' && <SsGeneration />}
            {page === 'ssautotag' && <SsAutoTag />}
            {page === 'pocloc' && <PocLoc />}
            {page === 'psartboard' && <PsArtboard />}
            {page === 'psactions' && <PsActions />}
            {page === 'pstext' && <PsText />}
            {page === 'psuxp' && <PsUXP />}
            {page === 'toolhealthcheck' && <ToolHealthCheck />}
            {page === 'tooltest' && <ToolTest />}
            {page === 'template' && <Template />}
            {page === 'onboarding' && <Onboarding />}
        </React.Fragment>
    )
}
